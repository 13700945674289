import React from 'react';
import './App.css';
import { InfinitySpin } from 'react-loader-spinner';

function App() {
  return (
    <div className="App">
      <div className='centerdiv'>
        <p className="loading-text2">hr@cybercoderztechnologies.com</p>
        <p className="loading-text"> Work In Progress...</p>
        <InfinitySpin
          visible={true}
          width="200"
          color="red"
          ariaLabel="infinity-spin-loading"
        />
      </div>
    </div>
  );
}

export default App;
